import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import StatusTable01 from 'components/Web_User_Interface/720p_Series/System/Status_LED/statusTable01';
import StatusTable02 from 'components/Web_User_Interface/720p_Series/System/Status_LED/statusTable02';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "System Menu // Status LED",
  "path": "/Web_User_Interface/720p_Series/System/Status_LED/",
  "dateChanged": "2017-12-10",
  "author": "Mike Polinowski",
  "excerpt": "Did you notice the blue and blinking LED on the camera? It can be very annoying in a dark room. But it can be used to troubleshoot your network connection or to make people aware that they are being recorded. For all other purposes come here to deactivate it.",
  "image": "./WebUI_720p_SearchThumb_System_Status_LED.png",
  "social": "/images/Search/WebUI_720p_SearchThumb_System_Status_LED.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "720p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='System Menu // Status LED' dateChanged='2017-12-10' author='Mike Polinowski' tag='INSTAR IP Camera' description='Did you notice the blue and blinking LED on the camera? It can be very annoying in a dark room. But it can be used to troubleshoot your network connection or to make people aware that they are being recorded. For all other purposes come here to deactivate it.' image='/images/Search/WebUI_720p_SearchThumb_System_Log.png' twitter='/images/Search/WebUI_720p_SearchThumb_System_Log.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/720p_Serie/System/Status_LED/' locationFR='/fr/Web_User_Interface/720p_Series/System/Status_LED/' crumbLabel="Status LED" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "720p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#720p-web-user-interface",
        "aria-label": "720p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`720p Web User Interface`}</h1>
    <h2 {...{
      "id": "system-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#system-menu",
        "aria-label": "system menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`System Menu`}</h2>
    <h3 {...{
      "id": "status-led",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#status-led",
        "aria-label": "status led permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Status LED`}</h3>
    <p><strong parentName="p">{`Deactivate your Camera´s Network LED`}</strong>{`
`}<em parentName="p">{`(if available)`}</em></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <blockquote>
      <p parentName="blockquote">{`Here you can adjust the behaviour of the red Status LED at the power connector of the camera. Enable it to have it display camera activity - which is especially helpful during the installation process. Or deactivate it for normal operation.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "716px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5181218713517f9dedfe4e083807b9ff/6bbf7/WebUI-System_StatusLED.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "104.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAIAAADJt1n/AAAACXBIWXMAAAsSAAALEgHS3X78AAACZklEQVQ4y5XS307aUBzAcR5s21Nsu1q2d/AptmVcqlGWyDltmdVBMdIo/QdEaamn7YGSaShMB1RboKUtJsR4wyIouIsR+Vye5JvfL+ecGJPNvn33/tXrNx8+fgIAEARB79IEJEASJJNJiqIgBCRFJra3E4kEQRAAQADA96kYy7Kbm5ufv3yFBGHWTF3TDYw1TdN13bKsVqvVbDZbrZZlWRcXF91ulyCItbU1WZYLhUJsl6ZTFJXJZE5OTq4uL2u1Wr1er5tmzaz3+33viT81HA57vR6EsFqtYoxjqRS1sbGR+pGSCpJt2+e/zpvN31E0CoLA87zBYPC8d13Xtu1sNqtNxSAE6+vre3v7giDc3NzY3a7juGEYBkEQhuFoNIqiKJyKosjzvOvr60wmo+v6QyxJ4vHxsaIohmF0Oh3TNDHGxpSu68ViSVXV5yftdpthmMfJ4/H4/v5+Mpnc3d05jtN9ptPpNBrWn6sr27bnh67rLiYfHubi8Xj8W5zn+fl6c7e3t7PN53zfT6fTj3EVVwWeQwg1Go35rS7hed4i9j0vGAaTyWQ8Hvd6vSAIhkv9Ew8Gg3a7Pdt25fjn/j6EBICQ54WVYwCSW1tbLMtyHOc4zmoxSZI7O0mKJPP5/MpxqVTK5XIsy5Zl2XXd1WJFUQRBEEVR0zTXdWePsUS/31/E5XKZ4zie5xFCvu/PfsUSYRgu4rOzM0EQisUiQujo6IhhmOz/HRwcpNNpmqYxxgihmCzL+XxeFEVVVSVJmm2xBMdxlUrlcTLGWFEUhJBhGBjj2gvMyof49PRUnKqoqqZp6AW0J38BjUMMGi9ua4oAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5181218713517f9dedfe4e083807b9ff/e4706/WebUI-System_StatusLED.avif 230w", "/en/static/5181218713517f9dedfe4e083807b9ff/d1af7/WebUI-System_StatusLED.avif 460w", "/en/static/5181218713517f9dedfe4e083807b9ff/04ac8/WebUI-System_StatusLED.avif 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5181218713517f9dedfe4e083807b9ff/a0b58/WebUI-System_StatusLED.webp 230w", "/en/static/5181218713517f9dedfe4e083807b9ff/bc10c/WebUI-System_StatusLED.webp 460w", "/en/static/5181218713517f9dedfe4e083807b9ff/d8378/WebUI-System_StatusLED.webp 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5181218713517f9dedfe4e083807b9ff/81c8e/WebUI-System_StatusLED.png 230w", "/en/static/5181218713517f9dedfe4e083807b9ff/08a84/WebUI-System_StatusLED.png 460w", "/en/static/5181218713517f9dedfe4e083807b9ff/6bbf7/WebUI-System_StatusLED.png 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5181218713517f9dedfe4e083807b9ff/6bbf7/WebUI-System_StatusLED.png",
              "alt": "Web User Interface - 720p Series - System Status LED",
              "title": "Web User Interface - 720p Series - System Status LED",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <StatusTable01 mdxType="StatusTable01" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "led-status-indicator",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#led-status-indicator",
        "aria-label": "led status indicator permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`LED Status Indicator`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <StatusTable02 mdxType="StatusTable02" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      